//
// Custom
// styles shop
//

.ex-catalog-item-block,
.ex-catalog-item-block.ex-catalog-item-variants>*,
.ex-catalog-item-block.ex-catalog-item-variants>*>*,
.ex-catalog-item-block .ex-title,
.ex-catalog-item-block .ex-price,
.ex-catalog-item-block .ex-price .ex-divider,
.ex-catalog-item-block .ex-status,
.ex-catalog-item-block .ex-gradient,
.ex-catalog-item-block .ex-digit {
    transition: all .35s ease 0s;
}
.ex-promotion-list .ex-status,
//.ex-promotion-list .ex-cart-btn-block,
.ex-promotion-list .ex-group-description,
.ex-promotion-list .ex-gradient {
    display: none;
}

.ex-promotion-list{
  margin: 0;
  padding: 0;
}

.product-slider .ex-promotion .ex-promotion-list .ex-cart-btn-block {
    display: block;
}

.ex-price .ex-ruble{
    padding-left: 4px;
}

.ex-dropdown-backdrop{
  display: inline-block;
}

.ex-icon-question{
    cursor: pointer;
    color: $gray-400;
  }

.ex-close{
    float: right;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    color: $mblack;
    background: transparent;
    -moz-opacity: .2;
    -khtml-opacity: .2;
    -webkit-opacity: .2;
    opacity: .2;
    -ms-filter: alpha(opacity=20);
    filter: alpha(opacity=20);
    text-decoration: none;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

.ex-catalog-item, .ex-catalog-item-detail, .products_same_group {
    display: inline-block;
    vertical-align: top;
    margin: 0 2% 25px 0;
    width: 23%;
    position: relative;
    z-index: 1;

  .ex-wrap-img img {
    max-width: 100%;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: alpha(opacity=100);
    filter: alpha(opacity=100);
    transition: all .2s ease-out 0s;
  }

  .ex-catalog-item-block {
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(0,0,0,0.35);
    transition: all .3s ease-in-out;
      &:hover,
        &:focus {
          background-color: #fff;
          -webkit-transform: translateY(-7px);
          -ms-transform: translateY(-7px);
          transform: translateY(-7px);
          box-shadow: 0 16px 24px rgba(0,0,0,0.3);
          border: 1px solid $gray-400;
        }
  }

  .ex-wrap-title .ex-title {
    max-height: 55px;
    min-height: 53px;
    line-height: 1.3;
    font-size: 17px;
  }

  .ex-price {
    font-size: 15px;

    .ex-divider {
      line-height: 22px;
      padding: 1px 5px;
      color: $gray-600;
      font-size: 14px;
    }
    .ex-unit{
      color: $gray-600;
    }

    .ex-divider:before {
      content: "/";
    }

  }

  .ex-badge {
    font-size: 13px;
  }

  .ex-action-wrapper {
    position: relative;
  }

  .ex-wrap-img {
    text-align: center;
    position: relative;
    padding: 100% 0 0;
    overflow: hidden;
    border-top-left-radius: var(--bs-border-radius-xl) !important;
    border-top-right-radius: var(--bs-border-radius-xl) !important;
  }

  .ex-wrap-img>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }

  .ex-ribbons {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 15px;
    left: -14px;
    z-index: 10;
    padding-left: 0;
    margin-left: 0;
    list-style: none;

    .ex-wrap-ribbon {
      position: relative;
      margin-bottom: 7px;

      .ex-ribbon{
       background-color: $danger;
      color: $white;
      padding: 6px 16px;
      display: inline-block;
      font-size: 13px;
      position: relative;
      right: 0;
      white-space: nowrap;
      line-height: 18px;
      text-transform: uppercase;
      font-weight: inherit;
      }
    }

    .ex-wrap-ribbon[data-tags~="white"] .ex-ribbon{
      background-color: #fff;
      color: #000;
    }
  }

  .ex-baloon {
    position: absolute;
    display: none;
    right: 99%;
    width: 100%;
    z-index: 1010;
    background-color: $white;
    min-width: 250px;
    border-radius: 4px;
    border: 1px solid $gray-400;
    top: -5px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    background-clip: padding-box;
    font-size: 15px;
    text-transform: inherit;
    font-weight: inherit;
    line-height: 24px;
    color: $mblack;
    padding: 6px 12px;

    .ex-attrs {
      padding-left: 0;
      margin-left: 0;
      list-style: none;

      .in-stock-status{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;

        &.ex-in-stock{
          color: $green;
        }
        &.ex-sold-out{
          color: $danger;
        }
        &.ex-out-of-stock{
          color: $danger;
        }
        &.ex-delivery{
          color: $warning !important;
        }

      }
    }
    .ex-baloon li {
      margin: 3px 0;
    }

    .ex-arrow {
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      right: -6px;
      top: 9px;
      border-left-color: $white;
      border-width: 6px 0 6px 6px;
    }

    .ex-arrow:after {
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      z-index: -1;
      content: "";
      border-left-color: $gray-400;
      border-width: 7px 0 7px 7px;
      bottom: -7px;
      right: -1px;
    }

  }

  .ex-wrap-title {
    height: 58px;
    text-align: center;
    position: relative;
    margin: 0 0 6px;
  }

  .ex-wrap-title .ex-title {
    background: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 6px 6px 0;
    //margin: 0 6px;
    overflow: hidden;
    max-height: 55px;
    min-height: 53px;
    line-height: 1.4;
    transition-property: max-height,background-color;
    font-size: 16px;
    text-transform: inherit;
    font-weight: inherit;
  }

  .ex-wrap-title .ex-title a {
    color: $mblack;
    display: inline-block;
    text-decoration: none;
  }

  .ex-price {
    font-size: 15px;
    text-align: center;
    line-height: 32px;
    color: $danger;
    transition-property: color;

    .ex-ruble{
      padding-left: 4px;
    }
  }

  .ex-in-stock .ex-status {
    color: $success;
  }

  .ex-status {
    font-size: 14px;
    min-height: 22px;
    line-height: 22px;
    text-transform: inherit;
    font-weight: inherit;
    transition-property: color;
  }

  .ex-cart-btn-block {
    padding-bottom: 10px;
    text-align: center;
    min-height: 66px;
  }

  .ex-cart-btn-block .ex-add-to-cart {
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 8px;
    margin-bottom: 0;
    position: relative;
    display: inline-block;
    font-weight: normal;
    text-transform: inherit;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    transition: all .2s ease-in 0s;
    transition-property: color,background-color,border-color;
    padding: 2px 14px;
    font-size: 29px;
    line-height: 46px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $gray-600;
    border-color: $gray-300;
    text-decoration: none;
  }

}


.ex-catalog-item[data-display-method="listed"] {
    display: block;
    width: 100%;
    margin-bottom: 7px;

    .ex-catalog-item-block {
      min-height: calc($ex-catalog-item-listed-img-width + 16px);

      &:hover, &:focus {
        transform: translateY(-3px);
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
      }
    }

    .ex-description-wrapper {
      position: relative;

      .ex-baloon {
        position: relative;
        display: block;
        width: 70%;
        top: 0;
        right: 0;
        padding-left: calc($ex-catalog-item-listed-img-width + 11px);
        background-color: transparent;
        min-width: 0;
        border: 0 none;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;

        li {
          font-size: 11px;
          color: #999;
          display: inline;
          margin-right: 3px;
        }

        .ex-arrow {
          display: none;
        }
      }
    }

    .ex-action-wrapper {
      min-height: 32px;
      padding-left: 5px;

      .ex-wrap-img {
        left: 0;
        position: absolute;
        padding: 0 !important;
        overflow: hidden;
        height: $ex-catalog-item-listed-img-height;
        width: $ex-catalog-item-listed-img-width;

        img {
          max-height: $ex-catalog-item-listed-img-height;
          width: $ex-catalog-item-listed-img-width;
        }
      }

      .ex-ribbons {
        float: left;
        position: relative;
        padding-left: $ex-catalog-item-listed-img-width + 4px;
        width: 100%;
        margin-bottom: -5px;
        left: 0;
        top: 0;
        display: inline-block;

        .ex-wrap-ribbon {
          line-height: 1;
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 0;
          overflow: hidden;

          &[data-tags~="hidden"] {
            display: none;
          }

          .ex-ribbon {
            padding: 1px 5px;
            font-size: 9px;
            line-height: 12px;

            &:after {
              border: 0 none;
            }

          }

          .ex-ribbon img {
            max-width: 45px !important;
          }
        }
      }

      .ex-status {
        text-align: left;
        display: inline-table;
        width: 70%;
        height: inherit;
        padding-right: 10px;
        margin: 0 0 0 2px;
        padding-left: $ex-catalog-item-listed-img-width + 4px;

        p {
          text-align: left;
        }
      }

      .ex-price {
        display: inline-block;
        position: relative;
        text-align: left;
        margin-right: 40px;
        font-size: 16px;
        vertical-align: top;

        .colored-stock {
          display: none;
        }
      }

      .ex-wrap-title {
        text-align: left;
        display: inline-table;
        width: 70%;
        height: inherit;
        padding-right: 10px;
        margin: -4px 0 0 2px;
        padding-left: $ex-catalog-item-listed-img-width + 4px;

        .ex-title {
          overflow: visible;
          position: relative;
          max-height: inherit;
          min-height: 0;

          a {
            vertical-align: text-top;
          }
        }
      }

      .ex-cart-btn-block {
        position: absolute;
        right: 1px;
        top: 0;
        min-height: 0;

        .ex-add-to-cart {
          margin-top: 1px;
        }
      }

      .ex-group-description {
        display: none;
      }
    }
  }

//hovered
.ex-catalog-item.ex-state-description,
.ex-catalog-item.ex-state-hover:hover{
    z-index: 10;

    .ex-wrap-title .ex-title {
      max-height: 232px;
    }

    .ex-wrap-title .ex-title a {
      color: $danger;
    }

    .ex-in-stock .ex-status {
      color: $success;
    }

    .ex-cart-btn-block .ex-add-to-cart {
      color: $danger;
      background-color: $white;
      border-color: $gray-300;
    }

    .ex-description-wrapper{
      .ex-baloon{
        display: block;
      }
    }

    .ex-wrap-img img {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    .ex-baloon{
      display: block;
    }
}

.ex-catalog-item[data-horizontal-position="left"] .ex-baloon {
    right: auto;
    left: 99%;

  .ex-arrow {
      right: auto;
      left: -6px;
      border-right-color: $white;
      border-width: 6px 6px 6px 0;
  }

  .ex-arrow:after {
    border-right-color: $gray-400;
    border-width: 7px 7px 7px 0;
    right: auto;
    left: -1px;
  }

}

.ex-promotion{
  .ex-promotion-title {
    .ex-category-title{
      margin-bottom: 1.5rem;
    }
    .ex-category-title a{
      color: $mblack;
      text-decoration: none;
      &:hover,
        &:focus {
          color: $danger;
        }
      }
  }
  /*
  .ex-promotion-statistic{
    display: none;
  }

  .ex-pagination>li{
    display: none;
  }*/

  .ex-promotion-list{
    clear: both;
    float: none;
    list-style: none;
    //.ex-cart-btn-block,
    .ex-status,
    .ex-group-description,
    .ex-gradient {
      display: none;
    }
  }

}

[class^="ex-icon-"], [class*=" ex-icon-"] {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

[class^="ex-icon-"], [class*=" ex-icon-"] {
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    display: inline;
    height: auto;
    line-height: normal;
    margin-top: 0;
    vertical-align: baseline;
    width: auto;
}

.ex-icon-cart:before {
    content: "\f07a";
}

[class^="ex-icon-"]:before, [class*=" ex-icon-"]:before {
    display: inline-block;
    text-decoration: inherit;
}

.ex-catalog-item .ex-ribbons .ex-wrap-ribbon[data-tags~="hidden"],
.ex-catalog-item-detail .ex-ribbons .ex-wrap-ribbon[data-tags~="hidden"],
.products_same_group .ex-ribbons .ex-wrap-ribbon[data-tags~="hidden"],
.ex-catalog-item .ex-ribbons .ex-wrap-ribbon[data-tags*="hidden-mark"],
.ex-catalog-item-detail .ex-ribbons .ex-wrap-ribbon[data-tags*="hidden-mark"],
.products_same_group .ex-ribbons .ex-wrap-ribbon[data-tags*="hidden-mark"],
.ex-catalog-item .ex-ribbons .ex-wrap-ribbon[data-tags*="seo-title"],
.ex-catalog-item-detail .ex-ribbons .ex-wrap-ribbon[data-tags*="seo-title"],
.products_same_group .ex-ribbons .ex-wrap-ribbon[data-tags*="seo-title"],
.ex-catalog-item .ex-ribbons .ex-wrap-ribbon[data-tags*="seo-h1"],
.ex-catalog-item-detail .ex-ribbons .ex-wrap-ribbon[data-tags*="seo-h1"],
.products_same_group .ex-ribbons .ex-wrap-ribbon[data-tags*="seo-h1"] {
    display: none;
}

.ex-catalog-item .ex-catalog-item-block{
  border: 1px solid $gray-300;
  &:hover,
    &:focus {
    background-color: #fff;
    -webkit-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    transform: translateY(-7px);
    box-shadow: 0 16px 24px rgba(0,0,0,0.3);
    //box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(220, 53, 69, 0.22);
  }
}

.ex-catalog-grid-5-col .ex-catalog-item {
    width: 18%;
}

.ex-catalog-grid-4-col .ex-catalog-item {
    width: 23%;
}
.catalog-filter-container.collapse{
  display: block;
}

.ex-js-filter-toggle:not(.collapsed) .b-show,
.ex-js-filter-toggle.collapsed .b-collapse{
  display: none;
}

@include media-breakpoint-down(xl) {
  .ex-catalog-grid-5-col .ex-catalog-item:not(.search-list) {
    width: 23%;
  }
  .ex-catalog-list .ex-catalog-item:not([data-display-method="listed"]){
    width: 31%;
  }
  .ex-catalog-item[data-display-method="listed"]{
    .ex-action-wrapper .ex-price{
      display: block;
      padding-left: 55px;
    }
  }
  .ex-catalog-grid-5-col.hide-tail{
    li:nth-child(5){
      display: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .ex-description-wrapper .ex-icon-question{
    display: none !important;
  }

  .ex-catalog-grid-5-col .ex-catalog-item:not(.search-list){
    width: 31%;
  }

  .ex-catalog-grid-5-col.hide-tail{
    li:nth-child(4){
      display: none;
    }
  }

  .ex-promotion .ex-promotion-statistic {
    display: none;
  }

  .ex-promotion .ex-promotion-pagination{
    width: 35%;
  }

  .catalog-filter-container.collapse:not(.show),
  .ex-catalog .ex-statistic{
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .ex-catalog-grid-5-col .ex-catalog-item:not(.search-list),
  .ex-catalog-list .ex-catalog-item:not([data-display-method="listed"]){
    width: 45%;
  }

  .ex-catalog-grid-5-col.hide-tail{
    li:nth-child(4){
      display: inline-block;
    }
  }
  .ex-catalog-item.ex-state-description .ex-description-wrapper .ex-baloon,
  .ex-catalog-item.ex-state-hover:hover .ex-description-wrapper .ex-baloon,
  .ex-catalog-item.ex-state-default[data-display-method="listed"] .ex-description-wrapper .ex-baloon,{
    display: none !important;
  }

}

@include media-breakpoint-down(sm) {
  .ex-catalog-grid-5-col .ex-catalog-item.search-list,
  .ex-catalog-list .ex-catalog-item:not([data-display-method="listed"]){
    width: 45%;
  }
  .ex-catalog-grid-5-col.search-ul{
    li:nth-child(4){
      display: inline-block;
    }
  }

  .ex-promotion .ex-promotion-title{
    width: 100%;
  }

  .ex-promotion .ex-promotion-pagination{
    width: 100%;
  }

}

.ex-promotion-title {
    position: relative;
    display: inline-block;
    width: 60%;
    float: left;
}

.ex-promotion-statistic {
    position: relative;
    display: inline-block;
    width: 25%;
    float: left;
    text-align: right;
    color: $mblack;
    //padding-top: 8px;
}

.ex-collapse {
    max-height: 0;
    overflow: hidden;
    position: relative;
}

// pagination
.ex-promotion-pagination {
    position: relative;
    display: inline-block;
    width: 15%;
    text-align: right;
}

.promo-prev-icon,
.promo-next-icon {
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
  background-color: $gray-400;
  opacity: 0.7;
  &:hover,
    &:focus,
      &.active{
        background-color: $mblack;
    }
}

.promo-prev-icon {
  background-image: escape-svg($carousel-control-prev-icon-bg);
}
.promo-next-icon {
  background-image: escape-svg($carousel-control-next-icon-bg);
}
.promo-control-prev,
.promo-control-next {
  position: relative;
  top: 0;
  bottom: 0;
  z-index: 1;
  // Use flex for alignment (1-3)
  display: inline-block; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  //width: $carousel-control-width;
  padding: 0;
  color: $carousel-control-color;
  text-align: center;
  background: none;
  border: 0;
  @include transition($carousel-control-transition);

  // Hover/focus state
  &:hover,
  &:focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: $carousel-control-hover-opacity;
  }
}
.promo-control-prev.ex-disabled,
.promo-control-next.ex-disabled{
  .promo-prev-icon,
  .promo-next-icon {
    &:hover,
      &:focus,
        &.active{
          background-color: $gray-400;
          cursor: initial;
      }
  }
}


.promo-control-prev {
  background-image: if($enable-gradients, linear-gradient(90deg, rgba($black, .25), rgba($black, .001)), null);
}

.promo-control-next {
  margin-left: 5px;
  background-image: if($enable-gradients, linear-gradient(270deg, rgba($black, .25), rgba($black, .001)), null);
}

.ex-filter-by, .ex-filter-by .btn, .ex-order-by, .ex-display-as, .ex-statistic, .ex-pagination {
  font-size: 14px;
}

.ex-display-as, .ex-display-as a,
.ex-howmany-items, .ex-howmany-items a,
.ex-pagination > li > a, .ex-pagination > li > span {
  font-size: 12px;
}

.ex-dropdown-menu2 {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 50px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: $white;
  border: 1px solid $gray-300;;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.ex-dropdown-menu2>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.5;
  color: $mblack;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 0;
}

.ex-dropdown-menu2>li>a:hover, .ex-dropdown-menu2>li>a:focus {
  color: $danger;
  text-decoration: none;
  border-bottom: 0;
  background-color: $white;
}

/*
==================== СОРТИРОВКА ТОВАРОВ ====================
*/

.ex-sort-dropdown {
  position: relative;
  .btn{
    font-size: 14px;
  }
}

.ex-state-opened .ex-sort-dropdown > .ex-dropdown-menu2 {
  display: block;
}

/*
==================== ПАДЖИНАЦИЯ ====================
*/

/*
==================== CATALOG ====================
*/

ul.ex-inline,
ol.ex-inline {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

ul.ex-catalog-list{
  padding-left: 0;

}

.glyphicon-chevron-down {
    background: escape-svg($chevron-down-icon);
    width: 16px;
    height: 16px;
    display: inline-block;
    background-position-y: 4px;
}

.glyphicon-chevron-up {
    background: escape-svg($chevron-up-icon);
    width: 16px;
    height: 16px;
    display: inline-block;
    background-position-y: 4px;
}

.glyphicon-chevron-right {
    background: escape-svg($chevron-right-icon);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ex-icon-reset {
    background: escape-svg($icon-reset);
    width: 16px;
    height: 16px;
    display: inline-block;
}


.colored-stock {
  svg.bi-circle-fill{
    padding-bottom: 2px;
    margin-bottom: 4px;
  }
}

.text-success-50{
  color: #1987487a;
}
