//
// Custom
// styles
//
//common

.bshadow {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
    margin-bottom: 12px;
}

.form-control::-moz-placeholder {
  color: $gray-400;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: $gray-400;
}
.form-control::-webkit-input-placeholder {
  color: $gray-400;
}

.fade.in {
    opacity: 1;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.header.nav-header {
  border-bottom: 1px solid $gray-400;
}

.header-menu-icon {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: center;
  color: $mblack;
  cursor: pointer;
  text-decoration: none;
  &:hover,
    &:focus {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      color: $danger;
    }
}

.cms-menu .nav-item .nav-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.cms-menu .dropdown-menu.show {
  z-index: 1500;
}

.sticky-top.header .header-logo {
  max-height: 120px;
  transition: max-height 0.4s linear;
}

.sticky-top.header.scrolled .header-logo {
  max-height: 50px;
}

//main-carusel
//main-carusel-img
.main-carusel-img{
  height: 476px;
  border: 1px solid $gray-400;
}

.main-carusel .carousel-caption {
  position: absolute;
  right: (100% - $carousel-caption-width) * .5;
  bottom: initial;
  left: (100% - $carousel-caption-width) * .5;
  padding-top: $carousel-caption-padding-y;
  padding-bottom: $carousel-caption-padding-y;
  color: $carousel-caption-color;
  text-align: left;
}

.main-carusel:hover .carousel-control-prev-icon,
.main-carusel:hover .carousel-control-next-icon {
  background-color: $mblack;
  opacity: 0.7;
  &:hover,
    &:focus {
    opacity: 0.9;
  }
}

.main-carusel:hover .carousel-control-prev,
.main-carusel:hover .carousel-control-next {
  opacity: 0.7;
  &:hover,
    &:focus {
    opacity: 0.9;
  }
}

.height-news {
  height: 141px;
  padding-bottom: 50px;
}

.news-title-sm {
  color: $mblack;
  text-decoration: none;
  cursor: pointer;
  &:hover,
    &:focus {
    color: $danger;
  }
}

.news-mark {
  float: right;
  color: $mblack;
}

.main-card-title {
  color: $mblack;
  text-decoration: none;
  cursor: pointer;
  &:hover,
    &:focus {
    color: $danger;
  }
}

.no-wrap {
  white-space: nowrap;
}

.main-card, .dates-card {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16), 0 1px 10px rgba(0, 0, 0, 0.12);
  &:hover,
    &:focus {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(220, 53, 69, 0.22);
  }
  .card-text{
    margin-bottom: 0.5rem;
  }
  .card-body{
    overflow: hidden;

    a{
      text-decoration: none;
    }

  }

}

.dates-card h6 {
  margin-top: 0.5rem;
}

//rgba(220, 53, 69, 0.22);
.main-news {
  height: 476px;
}

.main-news-button {
  color: $mblack;
  border: $btn-border-width solid $mblack;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  &:hover,
    &:focus {
    background-color: $danger;
    color: $white;
  }
}

.height-service {
  min-height: 250px;
}

.base-footer {
  border-top: 4px solid #e12243;
  //color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  background: $light;
}

.hidden {
  display: none;
}

.main-page-header .bg-body {
  border-bottom: 1px solid $gray-400;
}

// main category menu
.opacity-menu {
  max-height: 800px;
  overflow: auto;
  scroll-behavior: initial;
  opacity: 0.97;
  background-color: white;
}

.tab-content{

  h6 {
    font-size: 0.85rem;
    margin-bottom: 0.7rem;
  }

  .main-cat-tab-link {
    color: $danger;
    text-decoration: none;
    cursor: pointer;
    padding-right: 1.5rem;

  }

  .tab-pane {
    padding: 20px;
  }
}

.main-menu-tabs {
  .tab-content{
    .tab-pane {
      padding: 0;
    }
  }
}

.main-menu-contacts a {
  color: $mblack;
  text-decoration: none;
  text-align: right;
  cursor: pointer;
  padding-bottom: 10px;
  &:hover,
  &:focus,
    &.active{
      color: $danger;
  }
}

.main-menu-nav {
    margin-right: 1rem !important;
    padding-right: 1.2rem !important;
    border-right: 1px solid $gray-400;
}

.main-menu-nav .nav-link {
  padding: 0.3rem 1rem;
  margin-bottom: 0.4rem;
  color: $mblack;
  font-size: 14px;
  &:hover,
  &:focus,
    &.active{
      background-color: $gray-300;
      color: $black;
  }

}

h5 a.sub-link{
  font-weight: 600;
}

a.sub-link,
a.sub-sub-link {
  color: $mblack;
  text-decoration: none;
  cursor: pointer;
  &:hover,
    &:focus,
      &.active{
        color: $danger;
    }
}

a.sub-sub-link {
  padding-left: 1rem;
  font-size: 13px;
  font-weight: 400;
}
// main menu small

.container.sub-nav {
  max-height: 680px;
  overflow: auto;
  scroll-behavior: initial;
  background-color: rgba(255, 255, 255, 0.97);
}

.sm-menu-nav-link {
  display: block;
  text-align: left;
  width: 100%;
  font-size: 1.1rem;
  color: $mblack;

  &:hover,
    &:focus,
      &.active{
        color: $danger;
    }

}

a.sm-menu-nav-link {
  padding-left: 2rem;
}

.sm-menu-nav-link .bi,
.sm-menu-nav-sub-link .bi {
  transform: rotate(90deg);
}

.sm-menu-nav-link.collapsed .bi,
.sm-menu-nav-sub-link.collapsed .bi{
  transform: rotate(0deg);
}

.sm-menu-nav-sub-link-container {
  padding-left: 3rem;

  .sm-menu-nav-sub-link-container {
    padding-left: 1rem;

    .sub-sub-link{
      text-align: left;
      display: block;
    }


  }
}

.sm-menu-nav-sub-link.btn {
  padding-left: 0;
  text-align: left;
}

.main-card{
  .left-btn{
    width: auto;
  }
  .right-btn{
    width: auto;
    right: 20px!important;
  }
}

.all-service{
  .dropdown-toggle {
    margin-top: 3px;
  }
  .dropdown-menu.show{
    h6 {
      font-weight: bold;
      padding: var(--#{$prefix}dropdown-item-padding-y) var(--#{$prefix}dropdown-item-padding-x);
      color: var(--#{$prefix}navbar-color);
    }
  }
}

@include media-breakpoint-down(xxl) {
  .height-news{
    padding-bottom: 40px;
  }
  h6.card-title{
    margin-bottom: 0px;
  }
}
@include media-breakpoint-down(xl) {

  .main-carusel-img{
    height: 376px;
  }

  .main-card{
    padding-bottom: 40px;
  }

}


@include media-breakpoint-down(lg) {

  .main-carusel-img{
    height: 306px;
  }

  .cms-menu .nav-item .nav-link {
    font-size: 12px;
  }

  .main-card .card-text,
  .main-card .card-text ul{
    font-size: 0.9rem;
  }

  .nb-2{
    display: none !important;
  }

}

@include media-breakpoint-down(md) {

  h6.card-title{
    margin-bottom: 8px;
  }

  .nav-cms-menu-container{
    display: none;
  }

  .header-logo{
    max-height: 100px;
    text-align: center;
    padding-bottom: 15px;
  }

  .scrolled .header-logo,
  .scrolled .main-menu-contacts{
    display: none !important;
  }

  .main-carusel-img{
    height: 206px;
  }

  .main-carusel h2{
    font-size: 1.3rem !important;
  }
  .main-carusel .fs-4{
    font-size: 1.2rem !important;
  }

  .main-card .card-text,
  .main-card .card-text ul{
    font-size: 0.87rem;
  }

  .main-card{
    padding-bottom: 0px;
  }

}

@include media-breakpoint-down(sm) {
  .buttons-col {
    text-align: center;
    padding-top: 15px;
  }

  .main-carusel-img{
    height: 206px;
  }

  .main-carusel h2{
    font-size: 1rem !important;
  }
  .main-carusel .fs-4{
    font-size: 0.9rem !important;
  }

  .pub-image{
    max-height: 200px;
    margin-top: 1rem;
    margin-right: auto;
    margin-left: auto;
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
  }
  .owl-item{
    text-align: center;
  }
}

//helpers
.bi.hide-not-colapsed{
  display: none;
}

.collapsed .bi.hide-not-colapsed{
  display: initial;
}

.bi.hide-colapsed{
  display: initial;
}

.collapsed .bi.hide-colapsed{
  display: none;
}

.pub-item:hover{
  .card-title{
    color: $danger;
  }
}
.main-news-button-container{
    width: 90%;
    height: 50px;
    background-color: $white;
    position: absolute;
    bottom: 0px;
}

//news
.main-publication{
  transition: all 0.3s ease-in-out;
}

.main-publication-img{
  //box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16), 0 1px 10px rgba(0, 0, 0, 0.12);
  border: 1px solid $gray-400;
}

.main-publication:hover{
  .main-publication-img{
    transform: translateY(-3px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(220, 53, 69, 0.22);
  }
  .main-publication-text{
    transform: translateY(-3px);
  }
}

.main-publication a{
  color: $mblack;
  text-decoration: none !important;
  &:hover,
    &:focus,
      &.active{
        color: $danger;
    }
}

a{
  text-decoration: none !important;
}

a h2{
  color: $black;
  text-decoration: none !important;
  &:hover,
    &:focus,
      &.active{
        color: $danger;
    }
}

//search
.search-auto-list{
  margin-bottom: 0.25rem;
}

.search-auto-title{
  background-color: $danger;
  color: $white;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: end;
  font-size: 0.75rem;
}

//.search-auto-title.first{
//
//}

.search-auto-item{
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover,
  &:focus,
    &.active{
      background-color: $gray-300;
      color: $black;
  }

  a{
    color: $mblack;
  }
}

.search-auto-results{
  display: none;
}

.search-auto-results.show{
  position: absolute;
  display: block;

}

.search-auto-input:focus{
  box-shadow: -0.2rem 0.25rem 0.5rem 0.25px rgba(220, 53, 69, 0.25);
}
/*
.search-auto-btn{
  border-color: $border-color;
  color: $border-color;
}
*/

.search-auto-btn.focused{
  color: $danger;
  border-color: #ee9aa2;
  box-shadow: -0.2rem 0.25rem 0.5rem 0.25px rgba(220, 53, 69, 0.25);
  &:hover,
  &:focus,
    &.active{
      color: $white;
  }
}

.danger-hover-btn:hover{
  color: $white;
  background-color: $danger;
  border-color: $danger;
}

.danger-outline-hover-btn:hover{
  color: $danger;
  border-color: $danger;
  background-color: transparent;
}
/* CART APP */
/* Ncart */
.ncartdropdown{
  position: relative;
  display: inline-block;
}

.ncartdropdown.opened .ncartdropdown-items {
    display: block;
}

.ncartdropdown.opened .ncart-badge{
  z-index: 1000;
}

//.ncartdropdown.opened .ncartdropdown-main{
//  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
//}

.ncartdropdown-main{
  display: inline-block;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  padding: 6px 12px 0 12px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  z-index: 999;
  position: inherit;
}

.ncart-badge {
    border-radius: var(--bs-border-radius-pill) !important;
    background-color: $danger;
    transform: translate(-50%, -50%) !important;
    left: 100% !important;
    top: 0 !important;
    position: absolute !important;
    z-index: 100;
}

.ncartdropdown-items {
    min-width: 380px;
    position: absolute;
    left: auto;
    right: -1px;
    top: -1px;
    display: none;
    line-height: 25px;
    outline: 0;
    background-color: white;
    border-radius: 4px;
    border: 1px solid $gray-300;
    padding: 45px 15px 4px 15px;
    text-transform: inherit;
    font-weight: inherit;
    font-size: 14px;
    z-index: 980;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.ncart-product-item{
  line-height: 20px;
  margin: 8px 0px;
}
.ncart-product-item .quantity{
  color: $gray-500;
  float: left;
}

.ncart-product-item .title{
  padding-left: 5px;
  color: $danger;
  float: left;
}

.ncart-product-item .title a{
  color: $danger;
  cursor: pointer;
  text-decoration: none;
}

.ncart-product-item .title a:hover{
  text-decoration: underline;
}

.ncart-product-item .price{
  float: right;
  color: $mblack;
}

.ncart-product-item .del{
  float: right;
  color: $gray-500;
  padding-left: 10px;
  cursor: pointer;
}

.ncart-total{
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: $mblack;
}

.ncart-total{
  .total-title{
    float: left;
  }
  .total-price{
    float: right;
  }
}

.ncart-button-block{
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 15px;
}

.ncart-button{
  background-color: $danger;
  border-radius: 2px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  margin-bottom: 13px;
  min-width: 120px;
  padding: 10px 12px;
  line-height: 1.4;
  text-align: center;
  vertical-align: middle;
  color: $white;
  border-radius: var(--bs-border-radius)
}

a.ncart-button{
 color: $white;
 text-decoration: none;
}

.ncart-empty{
  text-align: center;
  font-size: 14px;
  color: $mblack;
}

.ncart-error{
    text-align: center;
    color: $danger;
}
.ncartdropdown-items-loader{
    text-align: center;
}

//mobile

#ncartmobile{
    display: none;
}

@media (max-width: 767px){
  .social-picture {
    width: 32px;
    margin-top: 5px;
  }

  #ncartmobile{
    display: block;
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 10000;
}

#ncartmobile .mobile-icon{
    font-size: 26px !important;
    position: relative !important;
    left: 12px !important;
    top: 12px !important;
    color: $white;
}

#ncartmobile .cart-badge{
    z-index: 100;
    position: absolute;
    top: -10px;
    right: 0px;
    text-align: center;
    background-color: $gray-400;
    display: inline-block;
    min-width: 10px;
    padding: 3px 3px;
    font-size: 14px;
    font-weight: bold;
    color: $white;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    border-radius: 2px;
}

#ncartmobile .mobile-button{
    background-color: $danger;
    border-radius: 2px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 28%);
    border: none;
    outline: none !important;
    overflow: hidden;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    border-radius: 50% !important;
    display: block;
}

#ncartmobile:hover .mobile-button{
    background-color: $white;
    border-color: $danger;
}
#ncartmobile:hover .mobile-icon{
    color: $danger;
}
}

/* add controls */

.ncart-item-unit{
    text-align: center;
    font-size: 12px;
    color: $mblack;
    font-weight: normal;
    padding-bottom: 10px;
}

.ncart-item-controls{
    width: auto;
    float: right;
}

.nquantity-control{
    bottom: -10px;
    width: 24%;
    float: left;
}

.nquantity-control:hover, .delete-control:hover{
  cursor: pointer;
  color: $danger;
}

.nquantity-control.disabled, .nquantity-control.disabled:hover{
  cursor: initial;
  color: $gray-400;
}

.ncart-form-control{
    width: 80px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid $gray-400;
    text-align: center;
    outline: none !important;
    &:hover,
    &:focus-visible {
      color: $danger;
      border-bottom: 2px solid $danger;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:focus-visible{
      color: $gray-400;
      border-bottom: 2px solid $gray-400;
    }
}

.ncart-item-quantity{
    font-size: 16px;
    padding-top: 10px;
}

.ncart-form-line{
    float: left;
    width: 50%;
  text-align: center;
}

.ncart-add-button.big{
    width: 100%;
}


/* Ncart  add product button */

.ncart-add-button,
.ncalck-add-button,
.nfav-add-button{
  position: relative;
  cursor: pointer;
  display: inline-block;
  background-color: $danger !important;
  color: $white !important;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 12px;
  padding: 10px 12px;
  min-width: 16.66666666666667%;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
  border-radius: 8px;
  border: none;
  font-size: 15px;
  z-index: 6;
}
.nfav-add-button{
  background-color: $warning !important;
}

.ncalck-add-button{
  background-color: $primary !important;
}

.ncart-add-button.small{
    min-width: 1%;
}

.ncart-add-button span{
    margin-left: 8px;
}

.ncart-add-button-badge {
    min-width: 10px;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: $danger;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: $white;
    border-radius: 2px;
    position: absolute !important;
    top: -5px;
    right: -5px;
    border: 1px solid $danger;
    border-radius: 50rem;
}

.ncart-add-button.disabled{
  background-color: $gray-400 !important;
  cursor: initial;
}

.ncart-add-button.disabled .ncart-add-button-badge{
  color: $gray-400 !important;
}

.ncart-add-button-loader{
    display: inline-block;
}

/* Ncart  detail page */

.cart-detail{
    width: 70%;
    float: left;
}

.cart-profile{
  width: 27%;
  float: right;
  padding: 0 0;
}

.ncart-conteiner {

  .delete-control{
    float: right;

    .material-icons{
      font-size: 14px;
    }

    &:hover{
      cursor: pointer;
      color: $danger;
    }
  }

  .ncart-loader{
    position: absolute;
    top: 120px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .ncart-title,
  .ncart-result{
    font-size: 15px;
    font-weight: 700;
  }

  .total{
    .price-title{
        padding-top: 10px;
    }
  }

  .ncart-result{
    float: right;
  }

  .help-link{
    margin-top: 12px;

    .material-icons{
      font-size: 18px;
    }
  }

  /* Card */
  .ncard {
    background: #fff;
    min-height: 50px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 30px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;

    .nbody {
      font-size: 14px;
      color: $gray-700;
      padding: 20px;
      /*margin-bottom: 20px;*/
      &.invoce-payment{
        h2 {
          font-size: 18px;
          font-weight: normal;
          color: $mblack;
        }
      }

      .form-control[disabled],
      .form-control[readonly],
      fieldset[disabled] .form-control {
        cursor: not-allowed;
        background-color: #eee;
        opacity: 1;
        border-radius: 0;
      }

    }

    .nheader {
      color: $gray-700;
      padding: 20px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.35);

      .ncol-xs-12 h2 {
        margin-top: 5px;
      }

      &.total{
        h2{
          color: $mblack;
        }
      }

      .nheader-dropdown {
        position: absolute;
        top: 20px;
        right: 15px;
        list-style: none;

        .ndropdown-menu li {
          display: block !important;
        }

        li {
          display: inline-block;
        }

        i {
          font-size: 20px;
          color: $gray-500;
          -moz-transition: all 0.5s;
          -o-transition: all 0.5s;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;

          &:hover{
            color: $mblack;
          }

        }
      }

      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        color: $mblack;

        small {
          display: block;
          font-size: 12px;
          margin-top: 5px;
          color: $gray-500;
          line-height: 15px;

          a {
            font-weight: bold;
            color: $gray-600;
          }
        }

      }
    }

    .ncard-inside-title {
      margin-top: 25px;
      margin-bottom: 15px;
      display: block;
      font-size: 15px;
      color: $mblack;

      &:first-child {
        margin-top: 0;
      }

      small {
        color: $gray-500;
        display: block;
        font-size: 11px;
        margin-top: 5px;

        a {
          color: $gray-600;
          font-weight: bold;
        }
      }
    }
  }

  .form-line{
    &.comment{
      padding-top: 20px;
    }
  }

}

.ncart-empty{
  text-align: center;

  h2{
    font-weight: normal;
  }
}

.ncart-delete-all{
  color: $gray-600;
  cursor: pointer;
  float: right;

  &:hover{
    color: $danger;
  }
}

/* Продукты */

.ncart-product.blur,
.cart-profile.blur,
.nbody.blur{
  filter: blur(3px);
}


.ncart-product{
  padding-bottom: 15px;

  &.product-disabled{
    color: $gray-500 !important;

    .ncart-art-heading,
    .ncart-item-unit,
    .ncart-discount-heading,
    .ncart-item-heading,
    .ncart-item-title,
    .ncart-item-title a
    {
      color: $gray-500 !important;
    }
  }
}


.ncart-item-heading,
.ncart-item-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: normal;
  color: $mblack;
  padding-top: 10px;

  a {
    color: $primary;
  }

}

.ncart-item-description {
    color: $gray-600;
    font-size: 13px;
}

.ncart-checked-button{
    float: left;
    vertical-align: middle;
    width: auto;
    padding-right: 15px;
    padding-top: 0px;
}

.ncart-image{
  float: left;
  width: 100%;

  img{
    max-width: 110px;
  }

}

.ncart-extra-price-padding{
  float: left;
  width: 20px;
}

.ncart-item-controls{
    width: auto;
    float: right;
}

.nquantity-control{
  bottom: -10px;
  width: 24%;
  float: left;

  &:hover{
    cursor: pointer;
    color: $danger;
  }

  &.disabled,
  &.disabled:hover {
    cursor: initial;
    color: $gray-400;

  }
}

.ncart-form-control{
    width: 80px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid $gray-400;
    text-align: center;
    outline: none !important;

  &:hover,
  &:focus-visible {
    color: $danger;
    border-bottom: 2px solid $danger;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus-visible,
  {
    color: $gray-400;
    border-bottom: 2px solid $gray-400;
  }

}

.ncart-item-quantity{
  font-size: 16px;
  padding-top: 10px;
}

.ncart-form-line{
  float: left;
  width: 50%;
  text-align: center;
}

.ncart-art-heading,
.ncart-item-unit,
.ncart-discount-heading{
  font-size: 12px;
  color: $gray-600;
  font-weight: normal;
}

.ncart-discount-heading{
  float: right;
  padding-top: 3px;
}

.ncart-item-unit{
  text-align: center;
}

.ncart-item-price{
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
}

.ncart-shipping-price{
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.ncart-extra-price, .ncart-extra-discount{
  text-align: center;
  padding-right: 12px;
  color: $gray-600;
}

.ncart-extra-price{
  text-decoration: line-through;
  margin-bottom: 5px;
  height: 20px;
}

/* Payment */

.ncart-payment{
  margin: auto;
  height: auto;
}

.ncart-payment-variant{
  display: inline-block;
  width: 27%;
  //float: left;
  margin: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
  cursor: pointer;

  &.active{
    box-shadow: 0 2px 10px $primary;

    .cash-payment-title{
      color: $primary;
    }

  }

  .cash-payment-title{
    font-size: 16px;
  }

  .payment-img{
    width: 100px;
  }

}

/* Users */

.ncart-title.discount,
.ncart-result.discount{
  color: $danger;
}

.ncart-total .ncart-title, .ncart-total .ncart-result{
  color: $mblack;
  font-size: 18px;
}

.ncart-registration{
  margin-right: 10px;
  padding-bottom: 3px;
  border-bottom: 1px dashed;
}

.ncart-order-btn{
  color: $white;
  background-color: $danger;
  padding: 10px 12px;
  margin-top: 20px;
}

.ncart-agry-button{
  size: 12px;
  padding-bottom: 10px;
}

.ncart-agry-button [type="checkbox"].ncart-checkbox + label {
  height: auto;
  font-size: 12px;
  line-height: 14px;
  padding-left: 30px;
}

.ncart-agry-button [type="checkbox"].ncart-checkbox:not(:checked) + label:after {
  top: 5px;
}

/* Result */
.result-modal{
  display: block;
}

/* CUSTOM CSS CHECKBOXES */
.ncart-conteiner form p {
  margin-bottom: 10px;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Remove default checkbox */
[type="checkbox"].ncart-checkbox:not(:checked),
[type="checkbox"].ncart-checkbox:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="checkbox"].ncart-checkbox + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

[type="checkbox"].ncart-checkbox + label:before,
[type="checkbox"]:not(.ncart-checkbox) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid $mblack;
  border-radius: 1px;
  margin-top: 2px;
  transition: .2s;
}

[type="checkbox"]:not(.ncart-checkbox) + label:after {
  border: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

[type="checkbox"].ncart-checkbox:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

[type="checkbox"].ncart-checkbox:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid $primary;
  border-bottom: 2px solid $primary;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"].ncart-checkbox:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}

/* Indeterminate checkbox */
[type="checkbox"].ncart-checkbox:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid $primary;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"].ncart-checkbox:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

[type="checkbox"].ncart-checkbox + label:after {
  border-radius: 2px;
}

[type="checkbox"].ncart-checkbox + label:before,
[type="checkbox"].ncart-checkbox + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1;
}

[type="checkbox"].ncart-checkbox:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

[type="checkbox"].ncart-checkbox:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid $gray-600;
  top: 0px;
  z-index: 0;
}

[type="checkbox"].ncart-checkbox:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid $white;
  border-bottom: 2px solid $white;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].ncart-checkbox:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid $primary;
  background-color: $primary;
  z-index: 0;
}

[type="checkbox"].ncart-checkbox.error-text:checked + label:after {
  border: 2px solid $danger;
  background-color: $danger;
}

[type="checkbox"].ncart-checkbox.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: $gray-600;
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].ncart-checkbox.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: $primary;
  border-color: $primary;
}

[type="checkbox"].ncart-checkbox:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type="checkbox"].ncart-checkbox:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: $gray-400;
}

[type="checkbox"].ncart-checkbox:disabled:checked + label:before {
  background-color: transparent;
}

[type="checkbox"].ncart-checkbox:disabled:checked + label:after {
  background-color: $gray-400;
  border-color: $gray-400;
}

[type="radio"] + label.ncart-radio-label{
  font-size: 14px;
}

[type="radio"]:checked + label.ncart-radio-label{
  font-weight: bold;
}

/* из styles */

.ncart-conteiner{

  button,
  input,
  select,
  a {
    outline: none !important;
  }

  label {
    margin-bottom: 5px;
  }

  [type="checkbox"] + label {
    font-weight: normal;
  }

  .nav-tabs {
    border-bottom: 2px solid $gray-200;

    li.active a {
      color: $primary !important;

      &:before {
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        //
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: 2px solid $primary;
        bottom: 2px;
        transition: 0.1s ease-in;
      }
    }
  }

  .nav-tabs > li > a {
    border: none !important;
    border-radius: 0;
    color: $gray-600 !important;
    position: relative;
    display: block;
    padding: 10px 15px;

    .material-icons {
      position: relative;
      top: 7px;
      margin-bottom: 8px;
    }

    &:hover,
    &:active,
    &:focus{
      background-color: transparent !important;
    }

  }

  .nav-tabs > li {
    position: relative;
    top: 3px;
    left: -2px;

  }

  .nav-tabs + .tab-content {
    padding: 15px 0;
  }

  [type="radio"]{
    margin: 4px 0 0;
    box-sizing: border-box;
    padding: 0;
    line-height: normal;

    &:checked,
    &:not(:checked),{
      position: absolute;
      left: -9999px;
      opacity: 0;
    }

    &.radio-col-light-blue:checked + label:after {
      background-color: $primary;
      border-color: $primary;
    }

    &:not(:checked) + label,
    &:checked + label {
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      display: inline-block;
      height: 25px;
      line-height: 25px;
      transition: .28s ease;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &:checked + label:before {
      border: 2px solid transparent;
    }

    &:not(:checked) + label:before,
    &:not(:checked) + label:after {
      border: 2px solid $gray-600;
    }

    &:not(:checked) + label:before,
    &:not(:checked) + label:after,
    &:checked + label:before,
    &:checked + label:after,
    &.with-gap:checked + label:before,
    &.with-gap:checked + label:after {
      border-radius: 50%;
    }

    &:checked + label:after {
      -webkit-transform: scale(1.02);
      transform: scale(1.02);
    }
  }

  [type="radio"] + label:before,
  [type="radio"] + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: .28s ease;
  }



  .modal.result-modal {

  .modal-header {
    border: none;
    padding: 25px 25px 5px 25px;
  }

  .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    box-shadow: 0 5px 20px rgb(0 0 0 / 31%) !important;
    border: none;

    .modal-body {
      color: $gray-600;
      padding: 15px 25px;
    }
  }

  .modal-footer {
    border: none;
  }

  .btn:not(.btn-link):not(.btn-circle) {
    box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    border: none;
    font-size: 13px;
    outline: none;
  }

}

  .invoce-same-adress [type="checkbox"].ncart-checkbox + label {
  font-size: 0.8rem;
  padding-left: 35px;
}

  .shipping-description{
  color: $gray-500;
  font-size: 13px;
}

  .p-t-20{
  padding-top: 20px;
}

  .nshipping-calc,
  .ncart-order-btn{
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  border: none;
}

  .nshipping-calc:hover{
    color: $primary !important;
    background-color: $white;
  }
  .ncart-order-btn:hover{
    color: $danger !important;
    background-color: $white;
  }

  .excel-save{
    cursor: pointer;
    font-size: 18px;
    font-weight: normal;
  }

  .excel-submit{
    box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
    -webkit-border-radius: var(--bs-border-radius);
    -moz-border-radius: var(--bs-border-radius);
    -ms-border-radius: var(--bs-border-radius);
    border-radius: var(--bs-border-radius);
    border: none;
    background-color: $white;
    color: $primary;
    margin-top: 12px;

    &.disabled {
      background-color: $gray-500;
      color: $white;
      box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%);
    }

    &:hover{
      color: $white;
      background-color: $primary;
    }
  }

  .file-allert{
    padding-bottom: 20px;
    color: $danger;
  }

  .popover-help{
    color: $primary;
    cursor: pointer;
  }

  .error-text,
  .ncart-item-title.error-text a{
    color: $danger !important;
  }

  /* Page Loader ================================= */
  *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .page-loader-wrapper {
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #eee;
    overflow: hidden;
    text-align: center;

    p {
      font-size: 13px;
      margin-top: 10px;
      font-weight: bold;
      color: #444;
    }

    .loader {
      position: relative;
      top: calc(50% - 30px);
    }

  }

  /* Preloaders ================================== */
  .md-preloader {

    .pl-red {
      stroke: #F44336;
    }

    .pl-pink {
      stroke: #E91E63;
    }

    .pl-purple {
      stroke: #9C27B0;
    }

    .pl-deep-purple {
      stroke: #673AB7;
    }

    .pl-indigo {
      stroke: #3F51B5;
    }

    .pl-blue {
      stroke: #2196F3;
    }

    .pl-light-blue {
      stroke: #03A9F4;
    }

    .pl-cyan {
      stroke: #00BCD4;
    }

    .pl-teal {
      stroke: #009688;
    }

    .pl-green {
      stroke: #4CAF50;
    }

    .pl-light-green {
      stroke: #8BC34A;
    }

    .pl-lime {
      stroke: #CDDC39;
    }

    .pl-yellow {
      stroke: #ffe821;
    }

    .pl-amber {
      stroke: #FFC107;
    }

    .pl-orange {
      stroke: #FF9800;
    }

    .pl-deep-orange {
      stroke: #FF5722;
    }

    .pl-brown {
      stroke: #795548;
    }

    .pl-grey {
      stroke: #9E9E9E;
    }

    .pl-blue-grey {
      stroke: #607D8B;
    }

    .pl-black {
      stroke: #000000;
    }

    .pl-white {
      stroke: $white;
    }
  }

  .preloader {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    -webkit-animation: container-rotate 1568ms linear infinite;
    -moz-animation: container-rotate 1568ms linear infinite;
    -o-animation: container-rotate 1568ms linear infinite;
    animation: container-rotate 1568ms linear infinite;

    &.pl-size-xl {
      width: 75px;
      height: 75px;
    }

    &.pl-size-l {
      width: 60px;
      height: 60px;
    }

    &.pl-size-md {
      width: 50px;
      height: 50px;
    }

    &.pl-size-sm {
      width: 40px;
      height: 40px;
    }

    &.pl-size-xs {
      width: 25px;
      height: 25px;
    }

    &.pl-size-exs{
      width: 16px;
      height: 16px;
    }

  }

  .spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    border-color: #F44336;
    -ms-opacity: 1;
    opacity: 1;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    -moz-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    -o-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;

      &.pl-red {
        border-color: #F44336; }
      &.pl-pink {
        border-color: #E91E63; }
      &.pl-purple {
        border-color: #9C27B0; }
      &.pl-deep-purple {
        border-color: #673AB7; }
      &.pl-indigo {
        border-color: #3F51B5; }
      &.pl-blue {
        border-color: #2196F3; }
      &.pl-light-blue {
        border-color: #03A9F4; }
      &.pl-cyan {
        border-color: #00BCD4; }
      &.pl-teal {
        border-color: #009688; }
      &.pl-green {
        border-color: #4CAF50; }
      &.pl-light-green {
        border-color: #8BC34A; }
      &.pl-lime {
        border-color: #CDDC39; }
      &.pl-yellow {
        border-color: #ffe821; }
      &.pl-amber {
        border-color: #FFC107; }
      &.pl-orange {
        border-color: #FF9800; }
      &.pl-deep-orange {
        border-color: #FF5722; }
      &.pl-brown {
        border-color: #795548; }
      &.pl-grey {
        border-color: #9E9E9E; }
      &.pl-blue-grey {
        border-color: #607D8B; }
      &.pl-black {
        border-color: #000000; }
      &.pl-white {
        border-color: #ffffff; }

  }

  .right {
    float: right !important;
  }

  .gap-patch {
    position: absolute;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;

    &.circle {
      width: 1000%;
      left: -450%;
    }
  }

  .circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;

    .circle {
      width: 200%;
      height: 100%;
      border-width: 3px;
      border-style: solid;
      border-color: inherit;
      border-bottom-color: transparent !important;
      -ms-border-radius: 50%;
      border-radius: 50%;
      -webkit-animation: none;
      animation: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &.left .circle {
      left: 0;
      border-right-color: transparent !important;
      -webkit-transform: rotate(129deg);
      -moz-transform: rotate(129deg);
      -ms-transform: rotate(129deg);
      -o-transform: rotate(129deg);
      transform: rotate(129deg);
      -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -moz-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -o-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    }

    &.right .circle {
      left: -100%;
      border-left-color: transparent !important;
      -webkit-transform: rotate(-129deg);
      -moz-transform: rotate(-129deg);
      -ms-transform: rotate(-129deg);
      -o-transform: rotate(-129deg);
      transform: rotate(-129deg);
      -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -moz-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -o-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    }

  }

  @-webkit-keyframes container-rotate {
    to {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg); } }

  @keyframes container-rotate {
    to {
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }

  @-webkit-keyframes fill-unfill-rotate {
    12.5% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg); }
    25% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg); }
    37.5% {
      -webkit-transform: rotate(405deg);
      transform: rotate(405deg); }
    50% {
      -webkit-transform: rotate(540deg);
      transform: rotate(540deg); }
    62.5% {
      -webkit-transform: rotate(675deg);
      transform: rotate(675deg); }
    75% {
      -webkit-transform: rotate(810deg);
      transform: rotate(810deg); }
    87.5% {
      -webkit-transform: rotate(945deg);
      transform: rotate(945deg); }
    to {
      -webkit-transform: rotate(1080deg);
      transform: rotate(1080deg); } }

  @keyframes fill-unfill-rotate {
    12.5% {
      transform: rotate(135deg); }
    25% {
      transform: rotate(270deg); }
    37.5% {
      transform: rotate(405deg); }
    50% {
      transform: rotate(540deg); }
    62.5% {
      transform: rotate(675deg); }
    75% {
      transform: rotate(810deg); }
    87.5% {
      transform: rotate(945deg); }
    to {
      transform: rotate(1080deg); } }

  @-webkit-keyframes left-spin {
    from {
      -webkit-transform: rotate(130deg);
      -moz-transform: rotate(130deg);
      -ms-transform: rotate(130deg);
      -o-transform: rotate(130deg);
      transform: rotate(130deg); }
    50% {
      -webkit-transform: rotate(-5deg);
      -moz-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      -o-transform: rotate(-5deg);
      transform: rotate(-5deg); }
    to {
      -webkit-transform: rotate(130deg);
      -moz-transform: rotate(130deg);
      -ms-transform: rotate(130deg);
      -o-transform: rotate(130deg);
      transform: rotate(130deg); } }

  @keyframes left-spin {
    from {
      -moz-transform: rotate(130deg);
      -ms-transform: rotate(130deg);
      -o-transform: rotate(130deg);
      -webkit-transform: rotate(130deg);
      transform: rotate(130deg); }
    50% {
      -moz-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      -o-transform: rotate(-5deg);
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg); }
    to {
      -moz-transform: rotate(130deg);
      -ms-transform: rotate(130deg);
      -o-transform: rotate(130deg);
      -webkit-transform: rotate(130deg);
      transform: rotate(130deg); } }

  @-webkit-keyframes right-spin {
    from {
      -webkit-transform: rotate(-130deg);
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      transform: rotate(-130deg); }
    50% {
      -webkit-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -o-transform: rotate(5deg);
      transform: rotate(5deg); }
    to {
      -webkit-transform: rotate(-130deg);
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      transform: rotate(-130deg); } }

  @-moz-keyframes right-spin {
    from {
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      -webkit-transform: rotate(-130deg);
      transform: rotate(-130deg); }
    50% {
      -moz-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -o-transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg); }
    to {
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      -webkit-transform: rotate(-130deg);
      transform: rotate(-130deg); } }

  @keyframes right-spin {
    from {
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      -webkit-transform: rotate(-130deg);
      transform: rotate(-130deg); }
    50% {
      -moz-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -o-transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg); }
    to {
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      -webkit-transform: rotate(-130deg);
      transform: rotate(-130deg); }
  }
}



  @media (min-width: 1201px) {
    .ncart-conteiner{
      display: grid;
    }

    .cart-profile{
      height: 100%;
    }
    .cart-profile .ncard{
      position: sticky;
      top: 0;
    }

    #ncart-detail-page div[itemtype="http://schema.org/WebPage"]{
      overflow: unset!important;
    }

  }

  @media (max-width: 1200px) {
    .cart-detail{
      width: 100%;
      float: none;
    }
    .cart-profile{
      width: 100%;
      float: none;
    }
  }

  @media (max-width: 767px){
    .container{
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: 100% !important;
      max-width: 100% !important;
    }

    .ncart-image img {
      max-width: 70px;
    }
    /*
    .ncart-item-price{
      width: 30%;
      float: right;
    }

    .ncart-item-quantity{
      width: 50%;
      float: left;
    }
    */
    .ncart-form-control{
      width: 60px;
    }

    .ncart-product{
      padding-bottom: 25px;
    }

    .ncart-item-unit{
      display: grid;
      min-width: 100px;
    }

    .ncart-extra-price-value{
      display: none;
    }

    .ncart-payment-variant{
      width: 90%;
      margin: 10px;
    }

    .cash-payment-title{
      width: 70%;
      display: inline-block;
    }

    .payment-img{
      width: 70px;
      display: inline-block;
    }

    .cash-payment-description{
      display: none;
    }

    .shipping-yandex-map{
      padding-top: 15px;
    }

    h4.nshipping-title{
      font-size: 16px;
    }

  }


/*
==================== User cabinet ====================
*/
.btn-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.btn-block+.btn-block {
    margin-top: 5px;
}

.norder-list {
  background: $white;
  min-height: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 30px;
  border-radius: 6px;

  &.bg-gray{
    background: $gray-100;
  }

  .nbody {
    padding: 20px;
  }

  .nheader {
    color: $mblack;
    padding: 20px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.35);
  }

  .nfooter {
    color: $mblack;
    padding: 20px;
    border-top: 1px solid rgba(204, 204, 204, 0.35);
  }

  .order-list-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h5 {
    font-weight: bold;
    line-height: 1.6;
  }

  .ncart-order {
    display: inline-block;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2), 0 2px 10px rgba(0,0,0,0.2);
    padding: 8px 8px 4px 8px;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    z-index: 999;

    &:hover{
      color: $white;
      background-color: $danger;
    }
  }
}


.user-cabinet-menu{
  background: $white;
  min-height: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 30px;
  border-radius: 6px;
  padding: 20px;

  .btn{
    white-space: initial;
  }

}

.cabinet-button{

  &.selected,
  &.nots:hover{
    position: relative;
    cursor: pointer;
    display: inline-block;
    background-color: $danger !important;
    color: $white !important;
    margin-right: 5px;
    margin-bottom: 12px;
    padding: 6px 12px;
    min-width: 16.66666666666667%;
    font-weight: 400;
    line-height: 2;
    text-align: center;
    white-space: inherit;
    vertical-align: middle;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 16%), 0 2px 10px rgba(0, 0, 0, 12%);
    border-radius: var(--bs-border-radius);
    border: none;
    font-size: 15px;
    &.smallnot{
      line-height: 1.2 !important;
    }
  }

  &.nots{
    position: relative;
    cursor: pointer;
    display: inline-block;
    background-color: $white !important;
    color: $mblack;
    margin-right: 5px;
    margin-bottom: 12px;
    padding: 6px 12px;
    min-width: 16.66666666666667%;
    font-weight: 400;
    line-height: 2;
    text-align: center;
    white-space: inherit;
    vertical-align: middle;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 16%), 0 2px 10px rgba(0, 0, 0, 12%);
    border-radius: var(--bs-border-radius);
    border: none;
    font-size: 15px;
    &.smallnot{
      line-height: 1.2 !important;
    }
  }
}


// MATERIALAZED FORM
.materialized-form {

  .form-group {

      .form-control {
        width: 100%;
        border: none;
        box-shadow: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        padding-left: 0;
        background: transparent;

        &[type="file"]{
          border: var(--bs-border-width) solid var(--bs-border-color);
          border-radius: var(--bs-border-radius);
          padding: 0.375rem 0.75rem;
        }
      }

      .form-line {
        width: 100%;
        position: relative;
        border-bottom: 1px solid $gray-300;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 0;
          bottom: -1px;
          -moz-transform: scaleX(0);
          -ms-transform: scaleX(0);
          -o-transform: scaleX(0);
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -moz-transition: 0.25s ease-in;
          -o-transition: 0.25s ease-in;
          -webkit-transition: 0.25s ease-in;
          transition: 0.25s ease-in;
          border-bottom: 2px solid $primary;
        }
      }
  }

  .input-group {
     width: 100%;
     margin-bottom: 20px;
     position: relative;
     border-collapse: separate;

     .form-error {
       text-align: center;
       width: 100%;
     }

    .input-group-addon {
      border: none;
      background-color: transparent;
      padding-left: 0;
      font-weight: bold;
    }

    .form-line {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid $gray-300;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        bottom: -2px;
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -moz-transition: 0.25s ease-in;
        -o-transition: 0.25s ease-in;
        -webkit-transition: 0.25s ease-in;
        transition: 0.25s ease-in;
        border-bottom: 2px solid $primary;
      }
    }

    input[type="text"],
    .form-control {
      border: none;
      box-shadow: none;
      padding-left: 0;
      border-bottom: 1px solid $gray-300;
      background: transparent;
      border-radius: 0;

      &:hover,
      &:focus{
        border-bottom: 1px solid $danger;
      }
    }
  }
}

.label{

  &.order-status-lable{
    //border-radius: 0;
    font-weight: normal;
    display: inline-block;
    white-space: initial;
    padding: 0.3em 0.6em 0.3em;
    font-size: 75%;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.35rem;
  }

  &.label-danger{
    color: $danger;
    border: 1px solid $danger;
  }

  &.label-warning{
    color: $secondary;
    border: 1px solid $secondary;
  }

  &.label-success{
    color: $success;
    border: 1px solid $success;
  }

  &.label-primary{
    color: $primary;
    border: 1px solid $primary;
  }
}

.price-row {
  line-height: 54px;

  .sku {
    font-size: 22px;
  }

  .ex-price{
    text-align: center;
    font-size: 34px;
    font-weight: 600;
  }

}

@each $color, $value in $colors {
  .mark-btn[data-tags~=#{$color}] {
    background-color: transparent;
    color: #{$value};
    border-color: darken($value, 10%);
  }

  .ex-catalog-item .ex-ribbons .ex-wrap-ribbon[data-tags~=#{$color}] .ex-ribbon,
  .ex-catalog-item-detail .ex-ribbons .ex-wrap-ribbon[data-tags~=#{$color}] .ex-ribbon,
  .products_same_group .ex-ribbons .ex-wrap-ribbon[data-tags~=#{$color}] .ex-ribbon {
    background-color: #{$value};
    color: color-contrast($value);

    &:after{
      border-color: darken($value, 10%) darken($value, 10%) transparent transparent;
    }
  }

}


@each $color, $value in $theme-colors {
  .mark-btn[data-tags~=#{$color}] {
    background-color: transparent;
    color: #{$value};
    border-color: #{$value};
  }

  .ex-catalog-item .ex-ribbons .ex-wrap-ribbon[data-tags~=#{$color}] .ex-ribbon,
  .ex-catalog-item-detail .ex-ribbons .ex-wrap-ribbon[data-tags~=#{$color}] .ex-ribbon,
  .products_same_group .ex-ribbons .ex-wrap-ribbon[data-tags~=#{$color}] .ex-ribbon {
    background-color: #{$value};
    color: color-contrast($value);

    &:after{
      border-color: darken($value, 10%) darken($value, 10%) transparent transparent;
    }
  }

}

//.ex-wrap-ribbon[data-tags
//.chr-row[data-tags~="hidden-mark"]
.mark-btn[data-tags~="hidden"],
.chr-row[data-tags~="hidden"],
.mark-btn[data-tags~="hidden-mark"]{
    display: none;
  }

.js-add-fav {
  &:hover,
  &:focus{
    color: $danger;
  }
}

.content-description {
  .nav-underline {
    .nav-link {
      margin-right: 30px;
      padding-right: 0;
      padding-left: 0;
      font-weight: 400;
      color: $gray-600;
      //color: #5f6468;

      &.active,
      &:hover,
      &:focus{
        color: $danger;
        font-weight: 400;
      }
    }

    .show > .nav-link {
      color: $danger;
      font-weight: 400;
    }
  }

  .tab-content .tab-pane {
    padding: 20px 0;
  }

  .product-tab-title{
    color: $mblack;
  }
}


/* jQuery.Rating Plugin CSS - http://www.fyneworks.com/jquery/star-rating/ */
div.rating-cancel,div.star-rating{float:left;width:20px;height:20px;text-indent:-999em;cursor:pointer;display:block;background:transparent;overflow:hidden}
div.rating-cancel,div.rating-cancel a{background:url(/static/lib/jquery-rating/img/delete.png) no-repeat 0 -18px}
div.star-rating,div.star-rating a{background:url(/static/lib/jquery-rating/img/star.png) no-repeat 0 0px}
div.rating-cancel a,div.star-rating a{display:block;width:20px;height:100%;background-position:0 0px;border:0}
div.star-rating-on a{background-position:0 -20px!important}
div.star-rating-hover a{background-position:0 -40px}
/* Read Only CSS */
div.star-rating-readonly a{cursor:default !important}
/* Partial Star CSS */
div.star-rating{background:transparent!important;overflow:hidden!important}
/* END jQuery.Rating Plugin CSS */

.review-box {
    display: flex;
    font-family: 'Open Sans', Arial, Sans-Serif;
    font-size: 13px;
    line-height: 1.8;
}

.review-box.no-margin-left {
    margin-left: 0;
}

.rating-box {
    width: 100px;
}

.rating-box input {
    display: none;
}

.rating-box .voting-box {
    position: absolute;
    margin-left: -20px;
    display: none;
}

.rating-box .voting-box form{
    margin: 0;
}

.rating-box:hover .voting-box {
    display: block;
}

.review-box .reviewers {
    margin: 0 7px;
    cursor: pointer;
}
.review-box .review-title {
    cursor: pointer;
}

.review-title.nobody-voted {
    display: none;
}

.chr-row {
  padding: 20px 0;
  border-bottom: 1px solid $gray-300;
  font-size: 0.875em;

  .chr-name {
    color: $gray-600;
  }
}

.soc-icon{
  display: inline-block;
  vertical-align: top;
  height: 24px;
  width: 24px;
  background-size: 24px 24px;
  line-height: 24px;
  cursor: pointer;
  padding: 0;
  margin: 2px 4px 0 0;
  border-radius: 4px;
}
.social-title{
  display: none;
}
.social-vk{
  background-color: #07f;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.878 17.304c-5.411 0-8.695-3.755-8.823-9.994h2.74c.086 4.583 2.171 6.528 3.77 6.925V7.31h2.627v3.954c1.542-.17 3.155-1.97 3.698-3.954h2.584c-.414 2.441-2.17 4.24-3.412 4.983 1.242.6 3.24 2.17 4.011 5.01h-2.84c-.6-1.898-2.07-3.369-4.04-3.569v3.57h-.315Z' fill='%23fff'/%3E%3C/svg%3E");
}
.social-telegram{
  background-color: #64a9dc;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.92 6.089L4.747 11.555c-.967.388-.962.928-.176 1.168l3.534 1.104 1.353 4.146c.164.454.083.634.56.634.368 0 .53-.168.736-.368.13-.127.903-.88 1.767-1.719l3.677 2.717c.676.373 1.165.18 1.333-.628l2.414-11.374c.247-.99-.378-1.44-1.025-1.146zM8.66 13.573l7.967-5.026c.398-.242.763-.112.463.154l-6.822 6.155-.265 2.833-1.343-4.116z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.social-ok{
  background-color: #f70;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.83 10.77a3.951 3.951 0 0 1-5.604 0 3.951 3.951 0 0 1 0-5.604 3.95 3.95 0 0 1 5.604 0 3.951 3.951 0 0 1 0 5.604Zm-2.802-4.615c-.494 0-.942.2-1.271.53a1.79 1.79 0 0 0 0 2.542 1.789 1.789 0 0 0 2.543 0 1.789 1.789 0 0 0 0-2.543 1.839 1.839 0 0 0-1.272-.53Zm4.168 5.792 1.166 1.59c.059.082.047.188-.036.247-.977.8-2.119 1.33-3.308 1.613l2.249 4.332c.059.13-.024.271-.165.271H13.7a.206.206 0 0 1-.176-.118l-1.496-3.579-1.507 3.567a.181.181 0 0 1-.176.118H7.943c-.141 0-.224-.153-.165-.27l2.249-4.321a8.262 8.262 0 0 1-3.308-1.613c-.071-.06-.095-.177-.036-.248l1.166-1.589c.07-.094.188-.106.27-.035 1.096.93 2.45 1.542 3.898 1.542s2.79-.6 3.897-1.542c.094-.07.223-.06.282.035Z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");
}

// Search

#base-search-results-container .mark-btn {
  max-width: 200px;
}

.blured {
    filter: blur(2px);
}

.big-spinner {
    width: 3rem; height: 3rem;
}

.search-spinner {
    position: absolute;
    left: 50%;
    top: 60%;
    z-index: 1111;
}

.red-link{
  cursor: pointer;
  &:hover,
    &:focus {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      color: $danger !important;
    }
  &.nav-link:hover{
    color: $danger !important;
    cursor: pointer !important;
  }
  &.nav-link{
    .active{
            color: $mblack !important;
    }
  }
}


.variant-product{
  cursor: pointer;
  &:hover,
    &:focus {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      color: $danger !important;
      border-color: $danger !important;
    }
}

blockquote {
    padding: 12.5px 25px;
    margin: 0 0 25px;
    font-size: 20px;
    border-left: 5px solid $gray-300;
}

.personal {
  .item {
    margin-bottom: 40px;

    p {
      font-size: 12px;
      line-height: 1.5;
    }

    img {
      box-shadow: 0 0 5px rgba(0,0,0,0.3);
      border-radius: 10px 10px 0 0;
    }
  }

  .feedback {
    border-radius: 0 0 4px 4px;
  }

}

.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 25px;
    line-height: 1.6;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.picture-block .thumbnail {
    position: relative;
    padding: 0;
    overflow: hidden;
}

.picture-block .thumbnail img {
    transition: all 1s ease-in-out 0s;
    -moz-transition: all 1s ease-in-out 0s;
    -webkit-transition: all 1s ease-in-out 0s;
    -o-transition: all 1s ease-in-out 0s;
}

.picture-block .thumbnail:hover img {
    transform: scale(1.5) rotateZ(-5deg);
    -moz-transform: scale(1.5) rotateZ(-5deg);
    -webkit-transform: scale(1.5) rotateZ(-5deg);
    -o-transform: scale(1.5) rotateZ(-5deg);
}

.thumbnail>img, .thumbnail a>img {
    margin-left: auto;
    margin-right: auto;
}

.img-responsive, .thumbnail>img, .thumbnail a>img, .carousel-inner>.item>img, .carousel-inner>.item>a>img {
    display: block;
    max-width: 100%;
    height: auto;
}

.bs-callout {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  padding: 1rem 1rem 1rem 2rem;
  border: 1px solid #eee;
}

.bs-callout-primary {
  border-left: 5px solid #0d6efd;
}

.bs-callout-secondary {
  border-left: 5px solid #6c757d;
}

.bs-callout-success {
  border-left: 5px solid #198754;
}

.bs-callout-info {
  border-left: 5px solid #0dcaf0;
}

.bs-callout-warning {
  border-left: 5px solid #ffc107;
}

.bs-callout-danger {
  border-left: 5px solid #dc3545;
}

.bs-callout-light {
  border-left: 5px solid #f8f9fa;
}

.bs-callout-dark {
  border-left: 5px solid #212529;
}

.bs-callout-blue {
  border-left: 5px solid #0d6efd;
}

.bs-callout-indigo {
  border-left: 5px solid #6610f2;
}

.bs-callout-purple {
  border-left: 5px solid #6f42c1;
}

.bs-callout-pink {
  border-left: 5px solid #d63384;
}

.bs-callout-red {
  border-left: 5px solid #dc3545;
}

.bs-callout-orange {
  border-left: 5px solid #fd7e14;
}

.bs-callout-yellow {
  border-left: 5px solid #ffc107;
}

.bs-callout-green {
  border-left: 5px solid #198754;
}

.bs-callout-teal {
  border-left: 5px solid #20c997;
}

.bs-callout-cyan {
  border-left: 5px solid #0dcaf0;
}

.bs-callout-black {
  border-left: 5px solid #000;
}

.bs-callout-white {
  border-left: 5px solid #fff;
}

.bs-callout-gray {
  border-left: 5px solid #6c757d;
}

.bs-callout-gray-dark {
  border-left: 5px solid #343a40;
}

.bs-callout-info {
  border-left: 5px solid #0d6efd;
}

.link-danger > a {
  color: #dc3545;
}
.link-danger > a:hover {
  color: #a71d2a;
}

*:has(> iframe.video-responsive) {
  position: relative;
  width: 100%;
  max-width: 720px;
  max-height: 406px;
  --bs-aspect-ratio: 75%;
}
*:has(> iframe.video-responsive)::before {
  display: block;
  padding-top: 410px;
  content: "";
}

iframe.video-responsive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 720px;
  max-height: 406px;
}

//Favorite
.ncart-form-control.add-group{
      min-width: 170px!important;
  }
  .material-icons.fav-edit-icon{
      cursor: pointer;
  }
  .material-icons.del-group{
      cursor: pointer;
      font-size: 0.9rem;
  }
  .mark-btn[data-tags~=selected] {
      background-color: $danger;
      color: $white;
      border-color: $danger;
      cursor: initial;
  }
  @media (max-width: 576px){
      .w-xs-100{
          width: 100%;
      }
  }

.fav-link-main{
      display: inline-block;
      border-radius: 2px;
      border: none;
      padding: 6px 12px 0 12px;
      margin-bottom: 0;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
}

.social-picture {
  width: 44px;
  margin-left: 10px;
  margin-top: 5px;
}


#popover-positioned-left.popover.left {
      margin-left: -10px;
    }

#popover-positioned-left > .popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

#popover-positioned-left.popover > .arrow {
  border-width: 11px;
}

#popover-positioned-left > .popover-content {
  padding: 9px 14px;
}

#popover-positioned-left.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, .25);
}

#popover-positioned-left.popover > .arrow, #popover-positioned-left.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

#popover-positioned-left.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  line-break: auto;
}


@media (max-width: 575.98px) {
  .ncartdropdown-items {
    min-width: 350px !important;
    width: 100% !important;
    margin-right: -50% !important;
    left: 45% !important;
    right: 0 !important;
    transform: translate(-50%, 0);
  }
  .ncart-product-item .title {
    max-width: 180px;
  }
  .buttons-col {
    text-align: center;
    padding-top: 15px;
  }
  .main-carusel-img {
    height: 206px;
  }
  .main-carusel h2, .main-carusel .h2 {
    font-size: 1rem !important;
  }
  .main-carusel .fs-4 {
    font-size: 0.9rem !important;
  }
  .pub-image {
    max-height: 200px;
    margin-top: 1rem;
    margin-right: auto;
    margin-left: auto;
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
  }
  .owl-item {
    text-align: center;
  }
  .service-cards .main-card {
    min-height: 270px;
  }

  .social-picture {
    width: 32px;
  }

  .main-card {
   .card-body {
     .container{
       padding-left: 0.5rem;
       padding-left: 0.5rem;

       .btn.position-absolute{
         position: relative!important;
       }
       .card-text{
          padding-bottom: 1rem;
       }
       .right-btn{
          right: 0 !important;
       }
     }
   }
  }
}
/*
.materialized-form {

  .input-group {
    width: 100%;
    margin-bottom: 20px;

    .input-group-addon{
      border: none;
      background-color: transparent;
      padding-left: 0;
      font-weight: 700;
    }
  }

  .form-line{
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid $gray-300;
    position: relative;

    .form-control{
      border: none;
      box-shadow: none;
      padding-left: 0;
    }
  }

}
**////
